import { useSelector } from 'react-redux';
import banner from 'assets/img/nfts/NftBanner1.png';
import {
  Box,
  Text,
  Button,
  Flex,
  Link,
  Image,
} from '@chakra-ui/react';
import { msalInstance } from '../../../microsoftMSAL/msalConfig';

const MicrosoftLogin = () => {
  const userInfo = useSelector((state) => state.usrinf);
  const signinMicrosoft = async () => {
    const loginRequest = {
      scopes: [
        'user.read',
        'OnlineMeetingRecording.Read.All',
        'OnlineMeetings.Read',
        'Calendars.Read',
        'OnlineMeetingTranscript.Read.All',
      ],
    };

    try {
      await msalInstance.initialize(); // Ensure MSAL instance is initialized
      const response = await msalInstance.loginPopup(loginRequest); // eslint-disable-line no-unused-vars
      window.location.reload();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  const getConsentUrl = () => {
    return `https://login.microsoftonline.com/common/adminconsent?client_id=56dff7fe-38b0-4a62-a56a-0b610a439e0f&redirect_uri=https://suitability.web.app/suitability-report`;
  };
  const getAdminConsent = () => { // eslint-disable-line no-unused-vars
    const consentUrl = getConsentUrl();
    window.location.href = consentUrl;
  };

  return (
    <Flex m="20px 20px 20px 0" w="80vw" h="100vh" overflow="auto"  flexDirection="column" justifyContent="space-between">
      <Box flex="0 1 auto">
        <Text sx={{ textStyle: 'dmsansNormal' }}>
          {userInfo.organisationName} / Clients
        </Text>
        <Text sx={{ textStyle: 'dmsansHeading' }} mb="20px">
          Meetings Overview
        </Text>
        <Flex
          direction="row"
          bgGradient="linear(to-b, avenirSuitabilityColor.600, avenirSuitabilityColor.800)"
          borderRadius="20px"
          justify="space-between"
        >
          <Flex
            py={{ base: '30px', md: '46px' }}
            px={{ base: '30px', md: '44px' }}
            direction="column"
            w="100%"
          >
            {' '}
            <Text
              fontSize={{ base: '24px', md: '34px' }}
              color="white"
              mb="14px"
              fontWeight="700"
              lineHeight={{ base: '32px', md: '42px' }}
              sx={{ textStyle: 'dmsansHeading' }}
            >
              Generate post-meeting summaries in no time
            </Text>
            <Text
              fontSize="md"
              color="#E3DAFF"
              fontWeight="500"
              mb="40px"
              lineHeight="28px"
              sx={{ textStyle: 'dmsansText' }}
            >
              Record with Teams, we do the rest! You can access transcript or
              generate a summary directly
            </Text>
            <Flex align="center">
              <Button
                bg="white"
                color="black"
                _hover={{ bg: 'whiteAlpha.900' }}
                _active={{ bg: 'white' }}
                _focus={{ bg: 'white' }}
                fontWeight="500"
                fontSize="14px"
                py="20px"
                px="27"
                me="38px"
                sx={{ textStyle: 'dmsansText' }}
                onClick={() => signinMicrosoft()}
              >
                Connect Microsoft Teams
              </Button>
              <Link
                href="https://calendly.com/avenir-andrea-macdonald/avenir-support"
                target="_blank"
                cursor="pointer"
              >
                <Text
                  color="white"
                  fontSize="sm"
                  fontWeight="500"
                  sx={{ textStyle: 'dmsansText' }}
                >
                  Get a feature tour with us
                </Text>
              </Link>
            </Flex>
          </Flex>
          <Image maxW="415px" src={banner} />
        </Flex>
      </Box>
      <Flex
        flex="1" 
        align="center" 
        justify="center" 
      >
        <Text
          sx={{ textStyle: 'dmsansNormal' }}
          fontSize="20px"
          color="#2B3674"
          fontWeight="700"
        >
          Meetings will appear below once you connected your Microsoft Teams
          account
        </Text>
      </Flex>
    </Flex>
  );
};
export default MicrosoftLogin;
