/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
//components
import V2UploadDocuments from '../components/V2UploadDocuments'
import V1SideNav from '../components/V1SideNav';
//Apis
import { getUploadPdfDetails } from 'services/apiServices';

const Dashboard = ({ sectinoChange, newReportDetails }) => {
  const sectionUploadChange = (value) => {
    sectinoChange(value);
  };
  const [uploadPdfsectiondetails, setUploadPdfsectiondetails] = useState();
  const fetchData = async () => {
    try {
      const response = await getUploadPdfDetails(newReportDetails.templateUID);
      setUploadPdfsectiondetails(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <Flex h="100%" overflow="auto">
        {uploadPdfsectiondetails?.length ? (
          <Flex>
            <V1SideNav></V1SideNav>
            <Flex w="80vw" justifyContent="center">
              <V2UploadDocuments uploadPdfsectiondetails={uploadPdfsectiondetails} sectionUploadChange={sectionUploadChange}/>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};
export default Dashboard;
