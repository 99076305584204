import { Flex } from '@chakra-ui/react';
import V1SideNav from '../components/V1SideNav';
import ReportsOverview from '../components/ReportsOverview';

const MyReports = () => {
  return (
    <Flex>
        <Flex>
          <V1SideNav></V1SideNav>
          <ReportsOverview></ReportsOverview>
        </Flex>
    </Flex>
  );
};

export default MyReports;
