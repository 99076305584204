import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { SidebarContext } from 'contexts/SidebarContext';

export function SuitabilityReport() {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const authBg = useColorModeValue('background.100', 'background.900');

  const getRoutes = (routes) => {
    return routes.flatMap((route, key) => {
      if (route.items) {
        return route.items.map((subRoute, subKey) => (
          <Route path={subRoute.path} element={subRoute.component} key={`${key}-${subKey}`} />
        ));
      } else {
        return <Route path={route.path} element={route.component} key={key} />;
      }
    });
  };

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Box
          bg={authBg}
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="/suitability-report/my-reports" replace />} />
              <Route path="*" element={<Navigate to="/suitability-report/my-reports" replace />} />
            </Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
