/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  DocumentEditorContainerComponent,
  Toolbar,
  SectionBreakType,
  Inject,
} from '@syncfusion/ej2-react-documenteditor';
import { useSelector } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  //   PdfSection,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import {
  /*getHeaderAndFooterData*/ postSyncEditorAnswer,
} from 'services/apiServices';
import { SYNCFUSION_LICIENSE_KEY } from 'static/constants';
// Registering Syncfusion license key
registerLicense(SYNCFUSION_LICIENSE_KEY);
// DocumentEditorContainerComponent.Inject(Toolbar);
const customToolbarItems = [
  'Undo',
  'Redo',
  'Image',
  'Table',
  'PageNumber',
  'Find',
  'Hyperlink',
  'Header',
  'Footer',
];
const tocSettings = {
  startLevel: 1,
  endLevel: 3,
  includeHyperlink: true,
  includePageNumber: true,
  rightAlign: true,
};

const SuitabilitySyncEditor = forwardRef(
  (
    {
      data,
      isGeneratePdfOrDoc,
      storedUserData,
      makeGeneratePdfDocNull,
      serverAnswerDetails,
    },
    ref,
  ) => {
    const currentReportUID = useSelector((state) => state.reportUID);
    const currentQuestionId = useSelector((state) => state.questionId);
    const editorRef = useRef(null);
    const container = editorRef.current;
    const applyPageBreak = () => {
      const editor = editorRef.current?.documentEditor;
      editor.editor.insertSectionBreak(SectionBreakType.NewPage);
    };
    const applyLineHeight = () => {
      if (editorRef.current) {
        const editor = editorRef.current?.documentEditor;
        editor.selection.selectAll();
        editor.selection.paragraphFormat.lineSpacingType = 'Multiple';
        editor.selection.paragraphFormat.lineSpacing = 1.3;
        editor.selection.moveToDocumentEnd();
        editor.selection.moveToNextLine();
      }
    };
    useEffect(() => {
      if (editorRef.current) {
        const editor = editorRef.current?.documentEditor;
        setTimeout(() => {
          if (editor && editor.editor && editor.selection) {
            if (serverAnswerDetails?.insertionPlace !== 'Bot') {
              editor.selection.goToPage(1);
              editor.selection.moveToDocumentStart();
              if (data.sfdt) {
                let res = 'a_' + currentQuestionId.previousQuestionId;
                editor.editor.insertBookmark(res);
                if (serverAnswerDetails?.tableOfContent) {
                  // Insert the Table of Contents
                  editor.editor.insertText('\n');
                  editor.editor.insertTableOfContents(tocSettings);
                  editor.editor.insertText('\n');
                  applyPageBreak();
                }
                // Step 2: Paste the SFDT content
                editor.editor.paste(data);
                editor.editor.insertText("\n");
                if (serverAnswerDetails?.pageBreak) {
                  applyPageBreak();
                }
                saveSyncEditorState();
              }
              setTimeout(() => {
                editor.selection.goToPage(1);
                editor.selection.moveToDocumentStart();
              }, 500);
            } else {
              const lastPage = editor.pageCount;
              editor.selection.goToPage(lastPage);
              editor.selection.moveToDocumentEnd();
              editor.selection.moveToNextLine();
              if (data.sfdt) {
                let res = 'a_' + currentQuestionId.previousQuestionId;
                editor.editor.insertBookmark(res);
                if (serverAnswerDetails?.tableOfContent) {
                  // Insert the Table of Contents
                  editor.editor.insertText("\n");
                  editor.editor.insertTableOfContents(tocSettings);
                  editor.editor.insertText("\n");
                  applyPageBreak();
                }
                // Step 2: Paste the SFDT content
                editor.editor.paste(data);
                editor.editor.insertText("\n");
                if (serverAnswerDetails?.pageBreak) {
                  applyPageBreak();
                }
                saveSyncEditorState();
              }
            }
            applyLineHeight();
          }
        }, 0);
      }
    }, [data]);
    const deleteBookmark = () => {
      const editor = editorRef.current?.documentEditor;
      const bookmarksList = editor.getBookmarks(false);
      let bookmarkName = 'a_' + currentQuestionId.questionId;
      if (editor && currentQuestionId?.questionId) {
        if (bookmarksList.includes(bookmarkName)) {
          editor.selection.selectBookmark(bookmarkName, false);
          editor.editor.delete();
          editor.editor.deleteBookmark(bookmarkName);
          saveSyncEditorState();
        } else {
          console.log('Bookmark "sfdtBookmark" not found.');
        }
      }
    };
    // Expose the function to the parent using useImperativeHandle
    useImperativeHandle(ref, () => ({
      deleteBookmark,
    }));
    const insertPageNumbers = (documentEditor) => {
      documentEditor.selection.goToFooter();
      documentEditor.editor.insertPageNumber();
      documentEditor.selection.paragraphFormat.textAlignment = 'Right';
      documentEditor.selection.closeHeaderFooter();
    };
    useEffect(() => {
      const documentEditor = editorRef.current.documentEditor;
      documentEditor.enableHeaderAndFooter = true;
      documentEditor.openBlank();
      insertPageNumbers(documentEditor);
    }, []);
    useEffect(() => {
      if (editorRef.current) {
        const editor = editorRef.current?.documentEditor;
        // Ensure the editor is loaded
        if (editor && editor.editor && editor.selection) {
          // Move the selection to the end of the document
          const lastPage = editor.pageCount; // Get the total number of pages
          editor.selection.goToPage(lastPage); // Go to the last page
          editor.selection.moveToDocumentEnd();
          editor.selection.moveToNextLine();
          if (storedUserData) {
            editor.editor.paste(storedUserData);
            saveSyncEditorState();
          }
          applyLineHeight();
          editor.selection.goToPage(1);
          editor.selection.moveToDocumentStart();
        }
      }
    }, [storedUserData]);
    //psot syncEditor Answer
    const saveSyncEditorState = async () => {
      let sfdtData = editorRef.current?.documentEditor?.serialize();
      const resbody = {
        reportUID: currentReportUID.reportUID,
        data: sfdtData,
      };
      const editor = editorRef.current?.documentEditor;
      editor.selection.moveToNextLine();
      postSyncEditorAnswer(resbody);
    };
    //Onclick to Generate Documents
    // const getHeaderFooterData = async () => {
      // const editor = editorRef.current?.documentEditor;
      // const res = await getUserSfdtHeader();
      // if (editor && res?.header?.sfdt?.length) {
      //   editor.selection.goToHeader();
      //   editor.selection.paragraphFormat.textAlignment = 'Right';
      //   editor.editor.paste(res.header);
      //   editor.selection.sectionFormat.headerDistance = 10;
      // editor.selection.goToFooter();
      // editor.editor.paste(res.footer);
    //   editor.selection.closeHeaderFooter();
    // }
  // };
  useEffect(() => {
    if (isGeneratePdfOrDoc === 'DOC') {
      editorRef.current.documentEditor.save(
        'avenir_suitability_report',
        'Docx',
      );
      setTimeout(() => {
        makeGeneratePdfDocNull()
      },[1000])
    }
    if (isGeneratePdfOrDoc === 'PDF') {
      let obj = container;
      let pdfdocument = new PdfDocument();
      let count = obj.documentEditor.pageCount;
      obj.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
      let loadedPage = 0;
      for (let i = 1; i <= count; i++) {
        // eslint-disable-next-line no-loop-func
        setTimeout(() => {
          // Getting pages as image
          let image = obj.documentEditor.exportAsImage(i, 'image/jpeg');
          image.onload = function () {
            let imageHeight = parseInt(
              image.style.height.toString().replace('px', ''),
            );
            let imageWidth = parseInt(
              image.style.width.toString().replace('px', ''),
            );
            let section = pdfdocument.sections.add();
            let settings = new PdfPageSettings(0);
            if (imageWidth > imageHeight) {
              settings.orientation = PdfPageOrientation.Landscape;
            }
            settings.size = new SizeF(imageWidth, imageHeight);
            section.setPageSettings(settings);
            let page = section.pages.add();
            let graphics = page.graphics;
            let imageStr = image.src.replace('data:image/jpeg;base64,', '');
            let pdfImage = new PdfBitmap(imageStr);
            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
            loadedPage++;
            if (loadedPage === count) {
              // Exporting the document as pdf
              pdfdocument.save(
                (obj.documentEditor.documentName === ''
                  ? 'avenir_suitability_report'
                  : obj.documentEditor.documentName) + '.pdf',
              );
            }
          };
        }, 500);
        setTimeout(() => {
          makeGeneratePdfDocNull()
        },[1000])
      }
    }
  }, [isGeneratePdfOrDoc]);
  // Function to apply inline styles to toolbar items
  const applyToolbarStyles = () => {
    const toolbarItems = document.querySelectorAll(
      '.e-de-toolbar .e-toolbar-item',
    );
    if (toolbarItems) {
      toolbarItems.forEach((item) => {
        item.style.paddingRight = '25px';
      });
    }
    const defaultCharacterFormat = {
      fontFamily: 'Arial'
    };
    editorRef?.current?.documentEditor?.setDefaultCharacterFormat(defaultCharacterFormat);
  };
  const setDefaultSyncfusionStyles = () => {
    if (editorRef.current) {
      const container = editorRef.current.documentEditor;

      // Define the styles as JSON strings
      const styles = [
        JSON.stringify({
          name: 'Heading 1',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 20,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 2',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 16,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 3',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 14,
            bold: true,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Heading 7',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 9,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          },
          paragraphFormat: {
            textAlignment: 'Left',
            beforeSpacing: 12,
            afterSpacing: 8,
            lineSpacing: 1.5,
          },
        }),
        JSON.stringify({
          name: 'Normal',
          type: 'Paragraph',
          characterFormat: {
            fontFamily: 'Arial',
            fontSize: 12,
            bold: false,
            italic: false,
            underline: 'None',
            fontColor: '#000000',
          }
        }),
        
      ];

      // Add each style individually
      styles.forEach((style) => {
        container.editor.createStyle(style, true);
      });
    }
  };
  // Apply styles after the component is rendered
  useEffect(() => {
    setTimeout(setDefaultSyncfusionStyles, 200);
    setTimeout(applyToolbarStyles, 100);
  }, []);
  return (
    // <Box>
    <DocumentEditorContainerComponent
      id="container"
      height="100%"
      ref={editorRef}
      style={{ height: '90% !important' }}
      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
      enableToolbar={true}
      showPropertiesPane={false}
      toolbarItems={customToolbarItems}
    >
      <Inject services={[Toolbar]} width="100%" />
    </DocumentEditorContainerComponent>
  );
});
export default SuitabilitySyncEditor;
