import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Text,
  VStack,
  Image,
  Flex,
  Icon,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Menu,
  Spinner,
  Alert,
  AlertIcon,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import pdf_icon from '../../../assets/img/SuitabilityReport/pdf_icon.png';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { CloseIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import { postUploadReport, postRemoveSectionPdf } from 'services/apiServices';

const V2UploadFIles = ({
  uploadDetails,
  fileName,
  documentlist,
  updateConsolidationReports,
  showDeleteOption,
  upadteIsFileUploaded,
  updateDisableSubmitButtonTemp,
}) => {
  const currentReportUID = useSelector((state) => state.reportUID);
  const fileInputRef = useRef(null);
  const [fileUploadError, setFileUploadError] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragLeave = () => {
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    // Iterate over all dropped files
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file.type !== 'application/pdf') {
          setFileUploadError('Only PDF files are allowed');
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 3000);
          return;
        }
        processFile(file);
      });
    }
  };

  const uploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const processFile = (file) => {
    const maxFileSize = 30 * 1024 * 1024;
    setUploadedFileNames((prevfiles) => ({
      ...prevfiles,
      [file.name]: false,
    }));

    if (file.size > maxFileSize) {
      setFileUploadError('This file is too heavy. Please retry');
      setShowErrorMessage(true);
      setTimeout(() => {
        setUploadedFileNames((prevFiles) => {
          const updatedFiles = { ...prevFiles };
          delete updatedFiles[file.name];
          return updatedFiles;
        });
        setShowErrorMessage(false);
      }, [3000]);
      return;
    }

    // Check if the file is a PDF
    if (file.type === 'application/pdf') {
      setFileUploadError('');
      setUploadedFiles((prev) => [...prev, file]); // Add file to the array
    }
  };

  const onFilesAddedEvt = (event) => {
    const files = Array.from(event.target.files);

    if (files && files.length > 0) {
      files.forEach((file) => {
        processFile(file);
      });
      event.target.value = null; // Clear the input after processing
    }
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      // Submit all files in the array
      uploadedFiles.forEach((file) => {
        submitPdf(file);
      });
      // Clear the array of uploaded files after processing
      setUploadedFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);
  const submitPdf = async (file) => {
    if (!file) return; // Ensure there's a file to upload
    let reportRes = {
      title: fileName,
      pdfFile: file,
      reportUID: currentReportUID.reportUID,
    };

    try {
      updateDisableSubmitButtonTemp(1);
      const res = await postUploadReport(reportRes);
      updateDisableSubmitButtonTemp(-1);
      if (res === 'CANNOT_READ') {
        setFileUploadError('Unable to read PDF, upload a valid PDF.');
        setShowErrorMessage(true);
        setTimeout(() => {
          setUploadedFileNames((prevFiles) => {
            const updatedFiles = { ...prevFiles };
            delete updatedFiles[file.name];
            return updatedFiles;
          });
          setShowErrorMessage(false);
        }, [3000]);
      } else {
        setUploadedFileNames((prevfiles) => ({
          ...prevfiles,
          [file.name]: true,
        }));
        upadteIsFileUploaded(1);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setFileUploadError('Unable to read PDF, upload a valid PDF.');
      setShowErrorMessage(true);
      setTimeout(() => {
        setUploadedFileNames((prevFiles) => {
          const updatedFiles = { ...prevFiles };
          delete updatedFiles[file.name];
          return updatedFiles;
        });
        setShowErrorMessage(false);
      }, [3000]);
    }
  };
  const removePdf = async (val) => {
    // Remove the file from uploadedFileNames state
    setUploadedFileNames((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[fileName];
      return updatedFiles;
    });

    // Remove the file from uploadedFiles state array
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName),
    );

    // Prepare the request for removing the file from the backend
    let res = {
      reportUID: currentReportUID.reportUID,
      title: val,
      category: fileName,
    };

    try {
      setUploadedFileNames((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        delete updatedFiles[val];
        return updatedFiles;
      });
      await postRemoveSectionPdf(res); // Call the API to remove the file from the server if needed
      upadteIsFileUploaded(-1);
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  const deleteUploadFIle = () => {
    updateConsolidationReports(fileName);
  };
  return (
    <Box
      w="49%"
      minHeight="40vh"
      overflow="auto"
      p="15px 10px 10px 10px"
      bg="white"
      borderRadius="12px"
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Flex w="100%" justifyContent="space-between">
        <Text
          sx={{ textStyle: 'dmsansHeading' }}
          color="#2B3674"
          fontSize="20px"
          fontWeight="700"
          mb="10px"
        >
          {fileName}
        </Text>
        {showDeleteOption ? (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FiMoreVertical />}
              _hover={{ bg: 'white' }}
              bg="white"
              boxSize="44px"
              border="0px !important"
            />
            <MenuList
              border="1px"
              borderColor="avenirGrey.200"
              borderRadius="8px"
              minWidth="auto" // Ensure that the menu list takes only the width required
              pt="0"
              pb="0"
            >
              <MenuItem
                _hover={{ bg: 'avenirGrey.100' }}
                px={4} // Padding for the items
                bg="white" // Ensure background stays white when not hovered
                borderRadius="10px 10px 0 0"
                fontFamily="DM Sans"
                fontWeight="500"
                justifyContent="flex-start"
                variant="ghost" // Make the button look like a normal text link
                width="100%"
                onClick={deleteUploadFIle}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        ) : null}
      </Flex>
      <Flex gap="3%" height={`calc(100% - 52px)`}>
        <Box w="60%">
          {!Object.keys(uploadedFileNames).length ? (
            <Flex
              bg="white"
              border="1px dashed #E0E5F2"
              borderRadius="13px"
              p="10px"
              height="100%"
              spacing={4}
              textAlign="center"
              backgroundColor="#FAFCFE"
              cursor="pointer"
              onClick={uploadFile}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={MdOutlineCloudUpload} color="#4E13C3" boxSize="50px" />
              <Text
                sx={{ textStyle: 'dmsansHeading' }}
                color="#8F9BBA"
                fontSize="12px"
                fontWeight="500"
                letterSpacing="-0.24px"
                lineHeight="20px"
                mt="20px"
              >
                Only PDFs are allowed
              </Text>
              <Flex
                as="span"
                sx={{ textStyle: 'dmsansHeading' }}
                fontSize="18px"
                fontWeight="700"
                letterSpacing="-0.36px"
                lineHeight="28px"
                flexDirection="column"
              >
                <Text as="span">Drop your files here, or&nbsp;</Text>
                <Text as="span" color="#4E13C3">
                  click to upload
                </Text>
              </Flex>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={onFilesAddedEvt}
                accept=".pdf"
              />
            </Flex>
          ) : (
            <VStack
              bg="white"
              border="1px dashed #E0E5F2"
              borderRadius="13px"
              p="10px"
              height="100%"
              justify="start"
              spacing={4}
              textAlign="start"
              alignItems="start"
              backgroundColor="#FAFCFE"
              mb={0}
              onClick={uploadFile}
              justifyContent="space-between"
            >
              <Flex flexDirection="column" gap="4px" height="100%">
                {Object.keys(uploadedFileNames).map(
                  (uploadPdfDetails, index) => (
                    <Flex
                      key={index}
                      h="40px"
                      w="260px"
                      bg="white"
                      boxShadow="0px 2px 2px 0px #D7D9E5"
                      borderRadius="8px"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex alignItems="center" ml="14px">
                        <Image src={pdf_icon} h="28px" w="24px" />
                        <Text
                          sx={{ textStyle: 'dmsansHeading' }}
                          color="black"
                          fontSize="12px"
                          fontWeight="700"
                          letterSpacing="-0.36px"
                          ml="14px"
                        >
                          {uploadPdfDetails.substr(0, 25)}
                        </Text>
                      </Flex>
                      {uploadedFileNames[uploadPdfDetails] ? (
                        <CloseIcon
                          h="25px"
                          w="11px"
                          mr="12px"
                          cursor="pointer"
                          color="#A3AED0"
                          onClick={(e) => {
                            e.stopPropagation();
                            removePdf(uploadPdfDetails);
                          }}
                        />
                      ) : (
                        <Spinner
                          thickness="1px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="#A3AED0"
                          h="14px"
                          w="14px"
                          mr="12px"
                        />
                      )}
                    </Flex>
                  ),
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={onFilesAddedEvt}
                  accept=".pdf"
                />
              </Flex>
              {showErrorMessage ? (
                <Alert
                  status="error"
                  bg={
                    fileUploadError === 'This file is too heavy. Please retry'
                      ? '#FF7A00'
                      : '#E31A1A'
                  }
                  color="white"
                  px="12px"
                  alignItems="center"
                  justifyContent="space-between"
                  height="36px"
                  borderRadius="12px"
                  w="90%"
                  alignSelf="center"
                >
                  <Flex alignItems="center">
                    <AlertIcon boxSize="18px" color="white" />
                    <Text
                      sx={{ textStyle: 'dmsansHeading' }}
                      fontSize="12px"
                      color="white"
                      fontWeight="400"
                      letterSpacing="0.2px"
                      lineHeight="12%"
                    >
                      {fileUploadError}
                    </Text>
                  </Flex>
                  <IconButton
                    aria-label="Close"
                    boxSize="14px" // Adjusts both height and width
                    icon={<CloseIcon boxSize="12px" />} // Adjust the size of the icon inside
                    color="white"
                    variant="ghost"
                    _hover={'none'}
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log('Closed');
                    }}
                  />
                </Alert>
              ) : null}
            </VStack>
          )}
        </Box>
        <Flex direction="column" gap="0px" w="40%">
          <Text
            sx={{ textStyle: 'dmsansHeading' }}
            color="#4E13C3"
            fontSize="18px"
            fontWeight="700"
          >
            Examples
          </Text>
          {documentlist &&
            documentlist.map((fileNames, index) => (
              <Flex key={index} ml="10px" align="baseline">
                <Box
                  as="span"
                  mr="12px"
                  fontWeight="bold"
                  fontSize="14px"
                  lineHeight="1"
                >
                  •
                </Box>
                <Box
                  sx={{ textStyle: 'dmsansHeading' }}
                  color="#2B3674"
                  fontSize="14px"
                  fontWeight="500"
                  letterSpacing="-0.36px"
                  lineHeight="24px"
                  dangerouslySetInnerHTML={{
                    __html: fileNames.name,
                  }}
                />
                {fileNames.description ? (
                  <Popover
                    trigger="hover"
                    placement="right-end" // Sets the initial placement to right-end
                    offset={[0, 10]}
                  >
                    <PopoverTrigger>
                      <IconButton
                        variant="ghost"
                        aria-label="Information"
                        icon={<InfoOutlineIcon />}
                        size="sm"
                        borderRadius="full"
                        color="#2B3674"
                        _hover={{
                          color: '#2B3674',
                        }}
                        cursor="pointer"
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg="white"
                        shadow="md"
                        w="230px"
                        zIndex="1500"
                        borderRadius="15px"
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                      >
                        <PopoverBody borderRadius="10px">
                          <Box
                            sx={{
                              textStyle: 'manropeText',
                              fontSize: '12px',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: fileNames.description,
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                ) : null}
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Box>
  );
};
export default V2UploadFIles;
